import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'partner_profiles',
  attributes: [
    'id',
    'name',
    'eng_name',
    'account_type',
    'company_info',
    'company_address',
    'company_contact_info',
    'company_contact_address',
    'company_bank_account_info',
    'company_advanced_info',
    'personal_id_info',
    'personal_bank_account_info',
    'personal_contact_address',
    'personal_residence_address',
    'personal_contact_info',
    'created_at',
    'updated_at'
  ],
  editableAttributes: []
}

export default class PartnerProfile extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }
}
