import * as types from './mutation-types'
import PromoterShare from '../../resource_models/promoter_share'

export * from './orders/actions'
export * from './products/actions.js'
export * from './product_categories/actions.js'

export * from './promoter_share_product_ships/actions.js'
export * from './promoter_event_product_ships/actions.js'
export * from './promoter_campaigns/actions.js'
export * from './promoter_withdrawal_requests/actions.js'
export * from './promoter_withdrawal_transactions/actions.js'
export * from './promoter_share_levels/actions'
export * from './promoter_campaign_main_groups/actions.js'
export * from './promoter_campaign_share_ships/actions.js'
export * from './promoter_group_share_ships/actions.js'

export const all = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'all')

  return new Promise((resolve, reject) => {
    PromoterShare.all(options)
      .then((response) => {
        commit(types.FETCH_PROMOTER_SHARES_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: all,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const find = ({ dispatch, commit }, id) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    PromoterShare.find(id)
      .then((response) => {
        commit(types.GET_PROMOTER_SHARE_SUCCESS, response)
        dispatch('promoterEvents/receiveResourcesFromRelationships', response, {
          root: true
        })
        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: find,
            ref: {
              dispatch,
              commit
            },
            params: id
          },
          {
            root: true
          }
        )
        reject(errors)
      })
  })
}

export const findWithoutProducts = ({ dispatch, commit }, id) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    PromoterShare.findWithoutProducts(id, { skip_product: 1 })
      .then((response) => {
        commit(types.GET_PROMOTER_SHARE_SUCCESS, response)
        dispatch('promoterEvents/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch(
          'promoterShareLevels/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch('promoterLevels/receiveResourcesFromRelationships', response, {
          root: true
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: find,
            ref: {
              dispatch,
              commit
            },
            params: id
          },
          {
            root: true
          }
        )
        reject(errors)
      })
  })
}

export const receiveResourcesFromRelationships = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RELATED_PROMOTER_SHARES_SUCCESS, response)

    resolve(response)
  })
}

export const receiveResourcesFromRelationshipsForSearch = (
  { commit },
  response
) => {
  return new Promise((resolve, reject) => {
    commit(types.FETCH_PROMOTER_SHARES_FOR_SEARCH_SUCCESS, response)

    resolve(response)
  })
}

export const save = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'save')
  return new Promise((resolve, reject) => {
    model
      .save()
      .then((response) => {
        if (model.isNewRecord()) {
          commit(types.ADD_PROMOTER_SHARE_SUCCESS, response)
        } else {
          commit(types.UPDATE_PROMOTER_SHARE_SUCCESS, response)
        }

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: save,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const toggle = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'toggle')

  return new Promise((resolve, reject) => {
    model
      .toggle()
      .then((response) => {
        commit(types.UPDATE_PROMOTER_SHARE_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggle,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const toggleIsAllowedForCampaignInvitation = (
  { dispatch, commit },
  model
) => {
  commit(types.API_REQUEST_START, 'toggleIsAllowedForCampaignInvitation')

  return new Promise((resolve, reject) => {
    model
      .toggleIsAllowedForCampaignInvitation()
      .then((response) => {
        commit(types.UPDATE_PROMOTER_SHARE_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggleIsAllowedForCampaignInvitation,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const revokeToken = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'revokeToken')

  return new Promise((resolve, reject) => {
    model
      .revokeToken()
      .then((response) => {
        commit(types.UPDATE_PROMOTER_SHARE_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: revokeToken,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateInfo = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateInfo')

  return new Promise((resolve, reject) => {
    model
      .updateInfo()
      .then((response) => {
        commit(types.UPDATE_PROMOTER_SHARE_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateInfo,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateSeo = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateSeo')

  return new Promise((resolve, reject) => {
    model
      .updateSeo()
      .then((response) => {
        commit(types.UPDATE_PROMOTER_SHARE_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        model.errors.record(errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateSeo,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateProfitConfigs = (
  { dispatch, commit },
  { model, shareProductShips }
) => {
  commit(types.API_REQUEST_START, 'updateProfitConfigs')

  return new Promise((resolve, reject) => {
    model
      .updateProfitConfigs({ shareProductShips: shareProductShips })
      .then((response) => {
        commit(types.UPDATE_PROMOTER_SHARE_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)

        commit(types.API_REQUEST_FAIL, errors)

        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateProfitConfigs,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchDashboardData = (
  { dispatch, commit },
  { dataType, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchDashboardData')
  return new Promise((resolve, reject) => {
    PromoterShare.fetchDashboardData(dataType, options)
      .then((response) => {
        commit(types.GET_DASHBOARD_DATA_SUCCESS, {
          response,
          dataType
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchDashboardData,
            ref: {
              dispatch,
              commit
            },
            params: {
              dataType,
              options
            }
          },
          {
            root: true
          }
        )
        reject(errors)
      })
  })
}

export const fetchDescendants = ({ dispatch, commit }, { model, options }) => {
  commit(types.API_REQUEST_START, 'fetchDescendants')

  return new Promise((resolve, reject) => {
    model
      .fetchDescendants(options)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'fetchDescendants')
        dispatch('promoterEvents/receiveResourcesFromRelationships', response, {
          root: true
        })
        dispatch('promoters/receiveResourcesFromRelationships', response, {
          root: true
        })
        commit(types.FETCH_DESCENDANT_PROMOTER_SHARES_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchDescendants,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const toggleVariantShipSoldOut = (
  { dispatch, commit },
  { model, shareVariantShipId }
) => {
  commit(types.API_REQUEST_START, 'toggleVariantShipSoldOut')

  return new Promise((resolve, reject) => {
    model
      .toggleVariantShipSoldOut({
        shareVariantShipId: shareVariantShipId
      })
      .then((response) => {
        commit(types.UPDATE_PROMOTER_SHARE_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggleVariantShipSoldOut,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

/**
 * toggle Promoter::Share `is_suspended` 的狀態
 *
 * @param {number} id 指定的 resource ID
 * @returns {promise} response or errors
 */
export const suspend = ({ dispatch, commit }, { model, options }) => {
  commit(types.API_REQUEST_START, 'suspend')
  return new Promise((resolve, reject) => {
    model
      .suspend(options)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_SHARE_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: suspend,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

/**
 * 審核通過加入 kolcenter
 *
 * @param {model, admin} model: 指定分潤會員賣場, admin: 指定的admin管理員
 * @returns {promise} response or errors
 */

export const approveToJoinKolcenter = ({ dispatch, commit }, { model }) => {
  commit(types.API_REQUEST_START, 'approve_to_join_kolcenter')
  return new Promise((resolve, reject) => {
    model
      .approveToJoinKolcenter()
      .then((response) => {
        commit(types.UPDATE_PROMOTER_SHARE_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: approveToJoinKolcenter,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const uploadAttachments = ({ dispatch, commit }, formData) => {
  commit(types.API_REQUEST_START, 'uploadAttachments')

  return new Promise((resolve, reject) => {
    PromoterShare.uploadAttachments(formData)
      .then((response) => {
        commit(types.UPLOAD_ATTACHMENTS_SUCCESS)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: uploadAttachments,
            ref: {
              dispatch,
              commit
            },
            params: formData
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchStats = ({ dispatch, commit }, { statsKey, options }) => {
  commit(types.API_REQUEST_START, 'fetchStats')

  return new Promise((resolve, reject) => {
    PromoterShare.fetchStats(options)
      .then((response) => {
        commit(types.GET_STATS_SUCCESS, {
          statsKey,
          response
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchStats,
            ref: {
              dispatch,
              commit
            },
            params: { statsKey, options }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchModelStats = (
  { dispatch, commit },
  { statsKey, options }
) => {
  commit(types.API_REQUEST_START, 'fetchModelStats')

  return new Promise((resolve, reject) => {
    PromoterShare.fetchModelStats(options)
      .then((response) => {
        commit(types.GET_STATS_SUCCESS, {
          statsKey,
          response
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchModelStats,
            ref: {
              dispatch,
              commit
            },
            params: { statsKey, options }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchBriefMemberInfo = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'fetchBriefMemberInfo')

  return new Promise((resolve, reject) => {
    PromoterShare.fetchBriefMemberInfo(options)
      .then((response) => {
        commit(types.FETCH_PROMOTER_SHARES_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchBriefMemberInfo,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const setCurrentKolShareId = ({ dispatch, commit }, shareId) => {
  return new Promise((resolve, reject) => {
    commit(types.SET_CURRENT_KOL_SHARE_ID, shareId)

    resolve(shareId)
  })
}
