<template lang="pug">
.vc-order-final-confirmation.odd-container.has-padding
  cart-item-list(
    :order="order"
    :cart-items="cartItems"
    :shipment-forms="shipments"
    :gift-items="giftItems"
    :cart-service="cartService"
    mode="show"
  )

  addresses-and-extra-info(
    :order="order"
    :cart-items="cartItems"
    :cart-service="cartService"
  )

  .cart-options
    back-to-store-button
    payment-button(
      :order="order"
      :cart-service="cartService"
      @back-to-first-step="backToFirstStepHandler"
    )
</template>

<script>
import checkoutFlowMixin from '../mixins/checkout_flow_mixin.js'
import CartItemList from './cart-item-list.vue'
import AddressesAndExtraInfo from './addresses-and-extra-info.vue'
import BackToStoreButton from './back-to-store-button.vue'
import PaymentButton from './payment-button.vue'
import useWarningInfo from '@/shared/composables/use_warning_info.js'

export default {
  components: {
    CartItemList,
    AddressesAndExtraInfo,
    BackToStoreButton,
    PaymentButton
  },

  mixins: [checkoutFlowMixin],
  // props: {},
  // data() {
  //   return {}
  // },
  computed: {
    giftItems() {
      return this.$store.getters['orderItems/fromCurrentOrder']('gift')
    },

    shipments() {
      return this.order.shipments.map((orderShipment) => {
        const shipment = this.$store.getters['orderShipments/find'](
          orderShipment.id
        )

        return {
          shipping_method_id: shipment.shipping_method.id,
          selectable_shipping_method_ids: [shipment.shipping_method.id],
          partner_id: shipment.partner_id,
          preferred_shipping_date: shipment.preferred_shipping_date,
          items: shipment.shipment_items.map((item) => {
            return {
              id: item.id,
              item_id: item.item_id
            }
          })
        }
      })
    }
  },
  // created() {},
  mounted() {
    this._tryFetchPaymentMethods()
    this._trackCheckoutProgress()

    useWarningInfo('cart_step_3', this.cartItems)
  },

  created() {
    useWarningInfo('cart_step_3_&_no_notes', this.cartItems)
  },

  methods: {
    backToFirstStepHandler() {
      this._scrollToTop()
      this.$emit('update:currentStep', 1)
    }
  }
}
</script>
